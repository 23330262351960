import React, { useEffect, useId, useMemo } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { Radio } from '../../components/atoms/Radio';
import { Select } from '../../components/atoms/Select';
import './htbspecifics.css';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { handleChange } from '../../store/offerToolSlice';
import nls from '../../nls/HTBToolBox.json';
import { Input } from '../../components/atoms/Input';
import { useLocation } from 'react-router-dom';

export const HtbSpecifics: React.FC = () => {
  const {
    offers: {
      pricing: { qualifiers, offerRack, bonusPoints },
      offerType,
      offerValidity: { minStayNights, maxStayNights },
      bookingDayLeadTime,
      cancellationAndCodes: { offerCodes }
    },
    showError,
  } = useSelector((state: any) => state.offerTool);

  const dispatch = useDispatch();
  const location = useLocation();
  // const minNight = parseFloat(minStayNights);
  // const maxNight = parseFloat(maxStayNights);
  const inputID = useId();

  let offerTypeOptions: { label: string; value: string }[] = [];
  let offerTypeLabel = '';
  let pageHeader = '';
  let subPageHeader = nls.HTB_FREE_NIGHT_PERCENT_OFF;

  const {
    isPercentOffDeal,
    isRackPlusAttributeDeal,
    isPriveAmexLuxDeal,
    isWohOffer
  } = useMemo(() => {
    const offerTypes = {
      isPercentOffDeal: false,
      isRackPlusAttributeDeal: false,
      isPriveAmexLuxDeal: false,
      isWohOffer: false
    }

    switch (offerType) {
      case 'htb-percent-off':
        offerTypes.isPercentOffDeal = true;
        break;
      case 'htb-rack-plus-attribute':
        offerTypes.isRackPlusAttributeDeal = true;
        break;
      case 'hyatt-prive-amex-luxury-consortia-discount-toolbox':
        offerTypes.isPriveAmexLuxDeal = true;
        break;
      case 'woh-bonus-points-toolbox-offer':
        offerTypes.isWohOffer = true;
        break;
    }

    return offerTypes;
  }, [offerType]);

  switch (offerType) {
    case 'htb-percent-off':
      offerTypeOptions = [
        { label: '10% Off', value: '10%' },
        { label: '15% Off', value: '15%' },
        { label: '20% Off', value: '20%' },
        { label: '25% Off', value: '25%' },
        { label: '30% Off', value: '30%' },
      ];
      offerTypeLabel = nls.PERCENT_OFF;
      pageHeader = nls.HTB_PERCENT_OFF;
      break;
    case 'htb-rack-plus-attribute':
      // OM-1505 percent off dropdown removed for htb-rack-plus-attribute offer type so offerTypeOptions is unused
      // https://jira.hyattdev.com/browse/OM-1505
      // offerTypeOptions = [
      //   { label: '10% Off', value: '10%' },
      //   { label: '15% Off', value: '15%' },
      //   { label: '20% Off', value: '20%' },
      //   { label: '25% Off', value: '25%' },
      // ];
      pageHeader = nls.HTB_RACK;
      subPageHeader = nls.OFFER_ATTRIBUTE;
      dispatch(handleChange({ name: 'websiteOffers', value: 'yes' }));
      dispatch(handleChange({ name: 'defaultOfferTitle', value: 'HTBVA Rack + Attribute' }));
      break;
    case 'hyatt-prive-amex-luxury-consortia-discount-toolbox':
      offerTypeOptions = [
        { label: '10% Off', value: '10%' },
        { label: '15% Off', value: '15%' },
        { label: '20% Off', value: '20%' },
        { label: '25% Off', value: '25%' },
        { label: '30% Off', value: '30%' },
      ];
      pageHeader = nls.HTB_PRIVE_AMEX_LUXURY_TITLE;
      subPageHeader = nls.HTB_PRIVE_AMEX_LUXURY_SUB_TITLE;
      break;
    case 'woh-bonus-points-toolbox-offer':
      offerTypeOptions = [
        { label: '500 Bonus Points Per Night', value: '500' },
        { label: '1000 Bonus Points Per Night', value: '1000' },
        { label: '1500 Bonus Points Per Night', value: '1500' },
        { label: '2000 Bonus Points Per Night', value: '2000' },
        { label: '2500 Bonus Points Per Night', value: '2500' },
        { label: '3000 Bonus Points Per Night', value: '3000' },
        { label: 'Double Your Points Per Night', value: 'double' },
        { label: 'Triple Your Points Per Night', value: 'triple' },
      ];
      pageHeader = nls.HTB_WOH_TITLE;
      subPageHeader = nls.HTB_WOH_SUB_TITLE;
      break;
  }

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '+' || e.key === 'e' || e.key === '-') {
      e.preventDefault();
    }
  };

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '' || (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 100)) {
      dispatch(handleChange({ name: 'bookingDayLeadTime', value: e.target.value }));
    }
  };

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 3 }));
    if (offerType !== '' && offerRack === '') {
      dispatch(handleChange({ name: 'percentOff', value: offerTypeOptions[0]?.value }));
    }
    if (offerType === 'woh-bonus-points-toolbox-offer' && bonusPoints === '') {
      dispatch(handleChange({ name: 'bonusPoints', value: '500' }));
    }
  }, []);

  return (
    <section className="container specific-main">
      <PageHeader title={pageHeader} description={subPageHeader} />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <div className="specific-options">
        {offerType === 'htb-rack-plus-attribute' && (
          <Input id='htb-specific-rack-text' dataAttribute='htb-specific-rack-text-input' type="text" placeHolder={nls.RACK_PLACEHOLDER_TEXT} label={nls.OFFER} readOnly />
        )}
        <>
          {!isPriveAmexLuxDeal && !isWohOffer &&
            <div className="specific-radio-main-container specific-gap">
              <p className="specific-text">* Who is eligible for this offer?</p>
              <div className="specific-radio">
                <Radio
                  id='htb-specific-eligible-option-1'
                  dataAttribute='htb-specific-eligible-option-1-all'
                  label="Both WOH Member Rate & All Channels"
                  value="all"
                  checked={qualifiers === 'all'}
                  onChange={(e) => {
                    dispatch(handleChange({ name: 'whoIsEligable', value: e.target.value }));
                  }}
                />
                <Radio
                  id='htb-specific-eligible-option-1'
                  dataAttribute='htb-specific-eligible-option-1-woh'
                  label="WOH Member Rate only"
                  value="woh"
                  checked={qualifiers === 'woh'}
                  onChange={(e) => {
                    dispatch(handleChange({ name: 'whoIsEligable', value: e.target.value }));
                  }}
                />
              </div>
              {showError && qualifiers === '' && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <WarningIcon width={15} height={15} />
                  <p style={{ fontSize: '12px', color: 'red' }}>Required Field</p>
                </div>
              )}
            </div>
          }
          {!isRackPlusAttributeDeal && (
            <>
              {(isPriveAmexLuxDeal || isWohOffer) && <p className="offer-update-label">{nls.HTB_OFFER_UPDATED_LABEL}</p>}
              <div className="specific-select-container">
                <Select
                  id='htb-specific-percent-off-option'
                  dataAttribute='htb-specific-percent-off-option-select'
                  className="specific-select "
                  labelClassName="specific-select-label"
                  label={offerTypeLabel}
                  options={offerTypeOptions}
                  value={isWohOffer ? bonusPoints : offerRack}
                  onChange={(e) => {
                    if (isWohOffer) {
                      dispatch(handleChange({ name: 'bonusPoints', value: e.target.value }));
                      if (offerCodes.length) {
                        let updatedOfferCodes = [...offerCodes];
                        let offerCodeValue = '';
                        const val = e.target.value;
                        switch (val) {
                          case '500':
                          case '1000':
                          case '1500':
                          case '2000':
                          case '2500':
                          case '3000':
                            offerCodeValue = val + 'PN'
                            break;
                          case 'double':
                            offerCodeValue = '2XPTS';
                            break;
                          case 'triple':
                            offerCodeValue = '3XPTS';
                            break;
                        }
                        updatedOfferCodes[0] = offerCodeValue;
                        dispatch(handleChange({ name: 'offerCodes', value: updatedOfferCodes }));
                      }
                    } else {
                      dispatch(handleChange({ name: 'percentOff', value: e.target.value }));
                    }
                  }}
                />
              </div>
            </>
          )}
        </>
      </div>
      {!isPriveAmexLuxDeal &&
        <>
          {isPercentOffDeal &&
            <>
              <hr className="htb-hr" />
              <div className="leadtime-container">
                <p className="leadtime-text-1">
                  * All hotel toolbox offers require a minimum of 1 booking day lead time
                </p>
                <div className="leadtime-input-main">
                  <input
                    type="number"
                    className="leadtime-input"
                    value={bookingDayLeadTime}
                    id={inputID}
                    onChange={handleOnchange}
                    onKeyDown={handleKey}
                  />
                  <label htmlFor={inputID} className="leadtime-input-label">
                    Day(s)
                  </label>
                </div>
                {showError && (bookingDayLeadTime === '' || bookingDayLeadTime < 1) && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WarningIcon width={15} height={15} />
                    <p style={{ fontSize: '12px', color: 'red' }}>Required Field</p>
                  </div>
                )}
                <p className="leadtime-min">Minimum 1 day lead time or greater</p>
              </div>
            </>
          }
          {/* Removed for OM-1540: https://jira.hyattdev.com/browse/OM-1540 */}
          {/* <hr className="htb-hr" />
          <div className="min-main">
            <p className="p-base-normal valid-days-para">
              If no minimum is required leave these fields blank.
            </p>
            <div className="max-min-input-container">
              <div className="min-container">
                <Input
                  id='htb-specific-min-stay-night'
                  dataAttribute='htb-specific-min-stay-night-input'
                  label="Minimum Length of Stay (Nights)"
                  labelClassName="p-base-small extra-space"
                  placeHolder=""
                  type="number"
                  name="minStayNights"
                  className="validays-input"
                  value={minNight ? Math.abs(minNight) : ''}
                  onChange={(e) =>
                    dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                  }
                />
                <span className="p-base-normal">{'Night(s)'}</span>
              </div>
              <div className="min-container">
                <Input
                  id='htb-specific-max-stay-night'
                  dataAttribute='htb-specific-max-stay-night-input'
                  label="Maximum Length of Stay (Nights)"
                  placeHolder=""
                  type="number"
                  name="maxStayNights"
                  labelClassName="p-base-small extra-space"
                  className="validays-input"
                  value={maxNight ? Math.abs(maxNight) : ''}
                  onChange={(e) =>
                    dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                  }
                />
                <span className="p-base-normal">{'Night(s)'}</span>
              </div>
            </div>
            {showError &&
              Number.isInteger(minNight) &&
              Number.isInteger(maxNight) &&
              maxNight < minNight && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <WarningIcon width={15} height={15} />
                  <p style={{ fontSize: '12px', color: 'red' }}>
                    Minimum length of stay value should not be greater than maximum length of stay
                    value.
                  </p>
                </div>
              )}
          </div> */}
        </>
      }
    </section>
  );
};
