export const distributionChannelReset: any = {
  qualifiedCustomers: {
    'percent-off-offer': {
      hyattCom: false,
      voiceAndHotelDirect: false,
      gds: false,
      otaStandalone: false,
      communicationMethod: [],
      distributionChannels: [],
      wholesaleConnected: false,
      wholesaleConnectedSpecificPartner: [],
      wholesaleNotConnected: false,
      wholesaleNotConnectedSpecificPartner: [],
      otaBundled: false,
      otaBundledSpecificPartner: [],
      luxuryConsortiaPartners: false,
      luxuryConsortiaPartnersSpecificPartner: [],
    },
    'hyattpriveluxury-offer': {
      priveWebsite: true,
      voiceAndHotelDirect: false,
      gds: true,
      otaStandalone: false,
      communicationMethod: [],
      distributionChannels: [],
      wholesaleConnected: false,
      wholesaleConnectedSpecificPartner: [],
      wholesaleNotConnected: false,
      wholesaleNotConnectedSpecificPartner: [],
      otaBundled: false,
      otaBundledSpecificPartner: [],
      luxuryConsortiaPartners: false,
      luxuryConsortiaPartnersSpecificPartner: [],
    },
  },
  nonQualifiedCustomers: {
    'percent-off-offer': {
      hyattCom: false,
      voiceAndHotelDirect: false,
      gds: false,
      otaStandalone: false,
      communicationMethod: [],
      distributionChannels: [],
      wholesaleConnected: false,
      wholesaleConnectedSpecificPartner: [],
      wholesaleNotConnected: false,
      wholesaleNotConnectedSpecificPartner: [],
      otaBundled: false,
      otaBundledSpecificPartner: [],
      luxuryConsortiaPartners: false,
      luxuryConsortiaPartnersSpecificPartner: [],
    },
    'hyattpriveluxury-offer': {
      priveWebsite: true,
      voiceAndHotelDirect: false,
      gds: true,
      otaStandalone: false,
      communicationMethod: [],
      distributionChannels: [],
      wholesaleConnected: false,
      wholesaleConnectedSpecificPartner: [],
      wholesaleNotConnected: false,
      wholesaleNotConnectedSpecificPartner: [],
      otaBundled: false,
      otaBundledSpecificPartner: [],
      luxuryConsortiaPartners: false,
      luxuryConsortiaPartnersSpecificPartner: [],
    },
  },
};

export const distribution = [
  {
    name: 'hyattCom',
    label: 'Hyatt.com',
  },
  {
    name: 'voiceAndHotelDirect',
    label: 'Voice and Hotel Direct',
  },
  {
    name: 'gds',
    label: 'GDS',
  },
  {
    name: 'otaStandalone',
    label: 'OTA - Standalone (OTA Public Rates)',
  },
];

export const showDistribution: any = {
  // qualifiedCustomers: {
  //   'package-inclusions-offer': {
  //     wholesaleConnected: true,
  //     wholesaleNotConnected: true,
  //     otaBundled: true,
  //   },
  //   'percent-off-offer': {
  //     wholesaleConnected: false,
  //     wholesaleNotConnected: false,
  //     otaBundled: false,
  //     otaStandalone: false,
  //     luxuryConsortiaPartners: false,
  //   },
  //   'free-night-offer': {
  //     wholesaleConnected: true,
  //     wholesaleNotConnected: true,
  //     otaBundled: true,
  //   },
  //   'advanced-purchase-offer': {
  //     wholesaleConnected: true,
  //     wholesaleNotConnected: true,
  //     otaBundled: true,
  //   },
  //   'weddingpromotion-offer': {
  //     wholesaleConnected: true,
  //     wholesaleNotConnected: true,
  //     otaBundled: true,
  //   },
  //   'hotelresortcredit-offer': {
  //     wholesaleConnected: true,
  //     wholesaleNotConnected: true,
  //     otaBundled: true,
  //   },
  //   'meetingpromotion-offer': {
  //     wholesaleConnected: true,
  //     wholesaleNotConnected: true,
  //     otaBundled: true,
  //   },
  //   'hyattpriveluxury-offer': {
  //     wholesaleConnected: false,
  //     wholesaleNotConnected: false,
  //     otaBundled: false,
  //   },
  // },
  nonQualifiedCustomers: {
    'package-inclusions-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'percent-off-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
      otaStandalone: true,
      luxuryConsortiaPartners: false,
    },
    'advanced-purchase-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'weddingpromotion-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'hotelresortcredit-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'meetingpromotion-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'hyattpriveluxury-offer': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'hyatt-prive': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
    'hyatt-luxury-consortia': {
      wholesaleConnected: true,
      wholesaleNotConnected: true,
      otaBundled: true,
    },
  },
  all: {
    'hyattpriveluxury-offer': {
      wholesaleConnected: false,
      wholesaleNotConnected: false,
      otaBundled: false,
    },
    'hyatt-prive': {
      wholesaleConnected: false,
      wholesaleNotConnected: false,
      otaBundled: false,
    },
    'hyatt-luxury-consortia': {
      wholesaleConnected: false,
      wholesaleNotConnected: false,
      otaBundled: false,
    },
  },
};
export const distribution2: any = {
  'package-inclusions-offer': {
    qualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    nonQualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'percent-off-offer': {
    qualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    nonQualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'advanced-purchase-offer': {
    qualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    nonQualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'weddingpromotion-offer': {
    qualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    nonQualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'hotelresortcredit-offer': {
    qualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    nonQualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'meetingpromotion-offer': {
    qualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    nonQualifiedCustomers: [
      {
        name: 'hyattCom',
        label: 'Hyatt.com',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'hyattpriveluxury-offer': {
    all: [
      {
        name: 'priveWebsite',
        label: 'Privé Website',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
      {
        name: 'otaStandalone',
        label: 'OTA - Standalone (OTA Public Rates)',
      },
    ],
  },
  'hyatt-prive': {
    all: [
      {
        name: 'priveWebsite',
        label: 'Privé Website (Privé Offers Only)',
      },
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
    noprive: [
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
  },
  'hyatt-luxury-consortia': {
    all: [
      {
        name: 'voiceAndHotelDirect',
        label: 'Voice and Hotel Direct',
      },
      {
        name: 'gds',
        label: 'GDS',
      },
    ],
  },
};
