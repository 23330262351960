import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { Select } from '../../components/atoms/Select';
import {
  handleChange,
  resetDistributionChennalAndInclutions,
  resetNonQualifiedDistributionChennalAndInclutions,
} from '../../store/offerToolSlice';
import { useSelector, useDispatch } from 'react-redux';

import './PricingDetails.css';
import Banner from '../../components/atoms/Banner';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Radio } from '../../components/atoms/Radio';
import { getPricingDropdown } from '../../service/api';
import useDropdownOptions from './useDropdownOptions';
import { useLocation } from 'react-router-dom';
import PricingDetailsModal from '../pricingDetails/pricingDetailsModal';
import { Input } from '../../components/atoms/Input';
import { PrivePricingDetail } from './priveScreen/privePricingDetails';
import NonQualifiedBookingDatesValidationModal from './NonQualifiedBookingDatesValidationModal';
interface OfferLabel {
  [key: string]: string;
}

const labelForOffer: OfferLabel = {
  'package-inclusions-offer': 'Offer Rate Details',
  'percent-off-offer': 'Offer Rate Details',
  'advanced-purchase-offer': 'Offer Rate Details',
  'weddingpromotion-offer': 'Offer Rate Details',
  'hotelresortcredit-offer': 'Offer Rate Details',
  'meetingpromotion-offer': 'Offer Rate Details',
  'hyattpriveluxury-offer': 'Offer Details',
  'hyatt-prive': 'Offer Details',
};

const offerTypeOptions = [
  { label: '3rd Night Free', value: '3rd Night Free' },
  { label: '4th Night Free', value: '4th Night Free' },
  { label: '5th Night Free', value: '5th Night Free' },
  { label: '6th Night Free', value: '6th Night Free' },
  { label: '7th Night Free', value: '7th Night Free' },
];
export const PricingDetails: React.FC = () => {
  const {
    offers: {
      offerValidity: { minStayNights, maxStayNights },
      pricing: { offerRack, offerTypeValue, qualifiers, freeNightDetails },
      offerType,
    },
    offerCategoriesAndTypes,
    showError,
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const location = useLocation();
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [nonQualifiedBookingDatesValidationModal, setNonQualifiedBookingDatesValidationModal] = useState(false);
  const [qualifiersHasValue, setQualifiersHasValue] = useState(false);
  const minNight = parseFloat(minStayNights);
  const maxNight = parseFloat(maxStayNights);

  useEffect(() => {
    if (freeNightDetails) {
      const numberOfNights = freeNightDetails.charAt(0);
      dispatch(handleChange({ name: 'minStayNights', value: numberOfNights }));
    }
  }, [freeNightDetails]);

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
    if (qualifiers === 'qualifiedCustomers' || qualifiers === 'nonQualifiedCustomers') {
      setQualifiersHasValue(true);
    }
  }, []);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-pricingdetails';
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === '.') {
      e.preventDefault();
    }
  };
  const {
    selectedRack,
    newOptions,
    optionDisableHandler,
    disableFirstRadioButton,
    disableSecondRadioButton,
  } = useDropdownOptions();
  const offer = offerCategoriesAndTypes[0]?.offerTypes?.find((o: any) => o.name === offerType);

  useEffect(() => {
    if (offerType === 'hyattpriveluxury-offer') {
      dispatch(getPricingDropdown('luxury-consortia-partners-types'));
    } else if (offerType === 'package-inclusions-offer') {
      dispatch(getPricingDropdown('offer-rack-details-types'));
    }
  }, [dispatch, offerType]);

  const resetDistributionChennalOptions = (selectedOption: string) => {
    if (selectedOption === 'qualifiedCustomers') {
      dispatch(resetNonQualifiedDistributionChennalAndInclutions());
    } else {
      dispatch(resetDistributionChennalAndInclutions());
    }
  };

  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 3 }));
  }, []);

  const onOfferRateChange = (e: any) => {
    optionDisableHandler(e);
    if (
      e.target.value === 'less-than-rack' &&
      qualifiers === 'nonQualifiedCustomers'
    ) {
      dispatch(handleChange({ name: 'bookingEndDate', value: '' }));
      location.state?.from === 'edit' && setNonQualifiedBookingDatesValidationModal(true);
    }
  };

  const discountValueLabel =
    offerType === 'package-inclusions-offer' ? 'Discount Value' : '* Discount Value';

  return (
    offerType === 'hyatt-prive' || offerType === 'hyatt-luxury-consortia' ?
      <PrivePricingDetail/>
    :
    <section className="container" style={{ alignItems: 'center' }}>
      <PageHeader
        title={`${offer?.title} Pricing Details`}
        description="If your offer involves complex pricing details or requires a rate grid, please use the attachments section."
      />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <div className="pricing-details-option">
        <div className="sub-option1">
          <span className="b-form-input__label p-base-normal">* {labelForOffer[offerType]}</span>
          <Select
            id='pricing-details-rack-value'
            dataAttribute='pricing-details-rack-input'
            className="blackout-date-input blackout-dates-select"
            label=""
            value={selectedRack}
            options={newOptions}
            onChange={onOfferRateChange}
            disabled={offerType === 'percent-off-offer'}
          />
          {showError && offerRack === '' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '-10px',
                marginBottom: '10px',
              }}
            >
              <WarningIcon width={15} height={15} />
              <p style={{ fontSize: '12px', color: 'red' }}>Choose an offer rack detail</p>
            </div>
          )}
          {offerType === 'percent-off-offer' && (
            <div className="offer-type">
              <span className="b-form-select__label p-base-normal">
                Offer Type: Percent Discount
              </span>
              <div className="discount-value">
                <label className="b-form-input offer-type-label">
                  <span className="b-form-input__label p-base-small">{discountValueLabel}</span>
                  <span className="b-form-input_has-icon offer-type-has-icon">
                    <span className="b-form-input__icon " id="percent-offer-type">
                      %
                    </span>

                    <input
                      className="b-form-input__control offer-type-input"
                      type="number"
                      name="offerTypeValue"
                      value={offerTypeValue}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (offerTypeValue >= 0) {
                          dispatch(handleChange({ name: e.target.name, value: e.target.value }));
                        }
                      }}
                      onKeyDown={handleKeyPress}
                    />
                    {showError && offerTypeValue === '' && offerType === 'percent-off-offer' && (
                      <div style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
                        <WarningIcon width={15} height={15} />
                        <p style={{ fontSize: '12px', color: 'red' }}>Provide a discount value</p>
                      </div>
                    )}
                  </span>
                </label>
              </div>
            </div>
          )}
        </div>
        <hr className="offer-code-hr" />
        <div className="sub-option2">
          <p className="p-base-normal" id="equal-1">
            * Who qualifies for this special offer?
          </p>
          <div className="pricing-details-checkbox">
            <Radio
              id='pricing-details-qualified-customers'
              dataAttribute='pricing-details-qualified-customers-radio'
              label="Qualified Customers"
              value={'qualifiedCustomers'}
              checked={!(qualifiers === '') && qualifiers === 'qualifiedCustomers'}
              labelClassName="p-base-medium"
              onChange={(e: any) => {
                if (e.target.checked) {
                  resetDistributionChennalOptions('qualifiedCustomers');
                  dispatch(handleChange({ name: 'qualifiers', value: e.target.value }));
                  if (qualifiersHasValue) {
                    setShowPricingModal(true);
                  }
                } else {
                  dispatch(handleChange({ name: 'qualifiers', value: '' }));
                }
              }}
              disabled={disableFirstRadioButton}
            />
            <h2 className="p-base-small">
              This offer is only for a specific targeted audience ex: Only Visa Card holders.
            </h2>
          </div>
          <div className="pricing-details-checkbox">
            <Radio
              id='pricing-details-non-qualified-customers'
              dataAttribute='pricing-details-non-qualified-customers-radio'
              label="Non-Qualified Customers"
              value="nonQualifiedCustomers"
              labelClassName="p-base-medium"
              checked={!(qualifiers === '') && qualifiers === 'nonQualifiedCustomers'}
              onChange={(e: any) => {
                if (e.target.checked) {
                  resetDistributionChennalOptions('nonQualifiedCustomers');
                  dispatch(handleChange({ name: 'qualifiers', value: e.target.value }));
                  if (qualifiersHasValue) {
                    setShowPricingModal(true);
                  }
                } else {
                  dispatch(handleChange({ name: 'qualifiers', value: '' }));
                }
              }}
              disabled={disableSecondRadioButton}
            />
            <h2 className="p-base-small">
              This offer is available to the public, anyone qualifies for this offer.
            </h2>
            {showError && (qualifiers === '' || qualifiers === 'undefined') && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '-30px' }}>
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Choose qualifiers for this offer</p>
              </div>
            )}
          </div>
        </div>
        <div className="min-max-main">
          <p className="p-base-normal valid-days-para">
            If no minimum or maximum is required leave these fields blank.
          </p>
          <div className="max-min-input">
            <div className="min-container">
              <Input
                id='pricing-details-min-stay-night'
                dataAttribute='pricing-details-min-stay-night-radio'
                label="Minimum Length of Stay (Nights)"
                labelClassName="p-base-small extra-space"
                placeHolder=""
                type="number"
                name="minStayNights"
                className="validays-input"
                value={Math.abs(minNight)}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                }
              />
              <span className="p-base-normal">{'Night(s)'}</span>
            </div>
            <div className="min-container">
              <Input
                id='pricing-details-max-stay-night-value'
                dataAttribute='pricing-details-max-stay-night-input'
                label="Maximum Length of Stay (Nights)"
                placeHolder=""
                type="number"
                name="maxStayNights"
                labelClassName="p-base-small extra-space"
                className="validays-input"
                value={Math.abs(maxNight)}
                onChange={(e) =>
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }))
                }
              />
              <span className="p-base-normal">{'Night(s)'}</span>
            </div>
          </div>
        </div>
      </div>
      {showPricingModal && <PricingDetailsModal handleClose={() => setShowPricingModal(false)} />}
      {nonQualifiedBookingDatesValidationModal && <NonQualifiedBookingDatesValidationModal handleClose={() => setNonQualifiedBookingDatesValidationModal(false)} />}
    </section>
  );
};
