import { useEffect, memo, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { useSelector, useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { routes } from './config/routes';
import { handleChange } from './store/offerToolSlice';
import './App.scss';
import { authenticateUser, getCSRFToken, getFeatureFlags, redirectToAEM } from './service/api';
import SiteMaintenance from './components/molecules/Error/SiteMaintenance';

const App = memo(() => {
  const { isLoading, featureList } = useSelector(({ offerTool }: any) => offerTool);
  const [allowRequest, setAllowRequest] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const getAuthenticated = async (token: any) => {
    const authResp = await authenticateUser(token);
    if (authResp) {
      sessionStorage.setItem(
        'offer-tool-authenticated-user',
        JSON.stringify({
          login_token: window.btoa(encodeURIComponent(token)),
          userID: authResp.authorizableId,
          username: authResp.name,
          group: authResp.groups,
          canCreateOffer: authResp.canCreateOffer
        })
      );
      if(authResp.canCreateOffer){
        dispatch(handleChange({ name: 'canCreateOffer', value: authResp.canCreateOffer }));
      }
      dispatch(handleChange({ name: 'authUser', value: authResp.name }));
    }
  };

  const isLoggedIn = async () => {
    const session = sessionStorage.getItem('offer-tool-authenticated-user');
    const url = new URL(window.location.href);
    const queryParamValue = url.searchParams.get('j_login_token');
    const queryParamValue1 = url.searchParams.get('?j_login_token');
    if (queryParamValue || queryParamValue1) {
      await getAuthenticated(queryParamValue ? queryParamValue : queryParamValue1);
      if (document.location.href.includes('offerPath=')) {
        window.history.pushState({}, '', document.location.href.split('&')[0]);
      } else {
        window.history.pushState({}, '', document.location.href.split('?')[0]);
      }
      setAllowRequest(true);
    } else if (session) {
      const csrfResp = await getCSRFToken();
      if (csrfResp) {
        const sessionObj = JSON.parse(session);
        if (sessionObj.userID && sessionObj.login_token) {
          if(sessionObj.canCreateOffer){
            dispatch(handleChange({ name: 'canCreateOffer', value: sessionObj.canCreateOffer }));
          }
          dispatch(handleChange({ name: 'authUser', value: sessionObj.username }));
          setAllowRequest(true);
        } else {
          redirectToAEM();
        }
      }
    } else {
      redirectToAEM();
    }
  };

  useEffect(() => {
    if (allowRequest) {
      dispatch(handleChange({ name: 'allowRequest', value: true }));
    }
  }, [allowRequest]);

  useEffect(() => {
    isLoggedIn().then(()=>{
      dispatch(getFeatureFlags());
    });
  }, []);

  useEffect(() => {
    const maintenanceModeFlag = featureList.find((feature: any) => feature.name === 'com.cortex.aem.offerservice.showMaintenancePage')
    if (maintenanceModeFlag && maintenanceModeFlag.enabled)
      setMaintenanceMode(true);
  }, [featureList]);

  if (!allowRequest || isLoading) {
    return <h1>Loading...</h1>;
  }
  return (
    <div className="app">
      <div className="main">
        {maintenanceMode ?
          <SiteMaintenance />
          : <RouterProvider router={routes} />
        }
      </div>
    </div>
  );
});

export default App;
