import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '../../components/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from '../../components/atoms/Radio';
import { PageHeader } from '../../components/molecules/PageHeader';
import { handleChange, setPropertyWebsiteOfferToDefault } from '../../store/offerToolSlice';
import './PropertyWebsite.css';
import { DatePickerComponent } from '../../components/molecules/datePicker';
import { format, parse } from 'date-fns';
import Banner from '../../components/atoms/Banner';
import nls from '../../nls/HTBToolBox.json';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import QuillEditor from '../../utils/QuillEditor';
import { setTimeTo9AMCST } from '../../utils/dateAndTimeUtils';
import { useLocation } from 'react-router-dom';
import { Checkbox } from '../../components/atoms/Checkbox';
import { isHtmlPresent, validateRTEText } from '../../utils/textUtils';
import { getImageNameFromHBM, parseHBMUrl } from '../../utils/fileUtils';
import { debounce } from '../../utils/utils';
import { imageValidation } from '../../service/api';

export const PropertyWebsite: React.FC = () => {
  const {
    offers: { websiteOffers, offerType },
    showError,
  } = useSelector((state: any) => state.offerTool);
  const dispatch = useDispatch();
  const location = useLocation();
  const [offerTitleHtmlPresent, setOfferTitleHtmlPresent] = useState(false);
  const [offerDescriptionHtmlPresent, setOfferDescriptionHtmlPresent] = useState(false);
  const [hbmUrlModified, setHbmUrlModified] = useState(false);
  const [hbmImageInValid, setHbmImageInValid] = useState(false);
  const [hbmAspectRatiosAvailable, setHbmAspectRatiosAvailable] = useState(true);
  const [hbmUrl, setHbmUrl] = useState('');
  const isPriveOffer = offerType === 'hyatt-prive';

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  useEffect(()=>{
    if(isPriveOffer){
      dispatch(handleChange({ name:'websiteOffers', value: 'yes'}));
    }
  },[offerType])

  useEffect(()=>{
    setHbmUrl(websiteOffers.brandManagerID);
  },[websiteOffers.brandManagerID])

  useEffect(() => {
    window.utag_data.page_name = 'offertool-specialoffer-propertywebsite';
  }, []);

  const getMaterialsDueDate = () => {
    try {
      if (websiteOffers.materialsDueDate) {
        const materialsDueDate = parse(
          websiteOffers.materialsDueDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSSX",
          new Date()
        );
        const formattedDate = format(materialsDueDate, 'MMM-dd-yyyy');
        return new Date(formattedDate);
      } else {
        return undefined;
      }
    } catch (error) { }
  };

  let pageHeader = isPriveOffer ? nls.HTB_PROPERTY_OFFER_TITLE_PRIVE : nls.HTB_PROPERTY_OFFER_TITLE;
  let subPageHeader = isPriveOffer ? nls.HTB_PROPERTY_OFFER_INSTRUCTIONS_PRIVE : nls.HTB_PROPERTY_OFFER_INSTRUCTIONS;

  let nonPriveAdditionalDescription = ''
  if(!offerType.includes('prive')){
    nonPriveAdditionalDescription = 'The information provided below will be guest facing.'
  }


  useEffect(() => {
    dispatch(handleChange({ name: 'page', value: 11 }));
  }, []);

  const languageSelection = (e: React.ChangeEvent<HTMLInputElement>, language: string) => {
    let clonedLanguageList = [...websiteOffers.languages];
    if (e.target.checked) {
      clonedLanguageList.push(language);
      dispatch(handleChange({ name: 'languages', value: clonedLanguageList }));
    }
    else {
      const index = clonedLanguageList.indexOf(language);
      clonedLanguageList.splice(index, 1);
      dispatch(handleChange({ name: 'languages', value: clonedLanguageList }));
    }
  }

  const debounceCallback = useCallback(
    debounce((value: any) => {
      const parsedUrl = parseHBMUrl(value);
      if (parsedUrl !== value) {
        setHbmUrlModified(true);
      }
      else {
        setHbmUrlModified(false);
      }
      dispatch(handleChange({ name: 'brandManagerID', value: parsedUrl }));
      imageValidation(getImageNameFromHBM(parsedUrl)).then((imageValidationResult) => {
        let image4x3 = false;
        let image16x9 = false;
        imageValidationResult?.hits?.forEach((result: any) => {
          if (result.name.includes('4x3')) {
            image4x3 = true;
          }
          if (result.name.includes('16x9')) {
            image16x9 = true;
          }
        });
        if (image4x3 && image16x9) {
          setHbmAspectRatiosAvailable(true);
        } else {
          setHbmAspectRatiosAvailable(false);
        }

      });
    }, 500),
    []
  );
  const hbmOnChangeHandler = ({ target: { value } }: { target: { value: string } }) => {
    setHbmUrl(value);
    debounceCallback(value);
  };
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = websiteOffers?.offerDescription;  
  return (
    <section className="container property-website">
      <PageHeader title={pageHeader} description={subPageHeader +' '+ nonPriveAdditionalDescription} />
      {showError && (
        <div style={{ width: '75%' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}
      <div className="property-website-info">
        {!isPriveOffer &&
          <>
            <p className="p-base-normal porperty-text ">
              Should this offer be included in the property website offers page?
            </p>
            <div className="property-website-radio">
              <Radio
                id='include-in-property-website-radio-button-1'
                dataAttribute='include-in-property-website-radio-button-yes'
                label="Yes"
                labelClassName="p-base-medium radio-button-align"
                name="websiteOffers"
                value="yes"
                checked={websiteOffers.isOffer}
                onChange={(e) => {
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }));
                }}
              />
              <Radio
                id='include-in-property-website-radio-button-2'
                dataAttribute='include-in-property-website-radio-button-no'
                label="No"
                labelClassName="p-base-medium radio-button-align"
                name="websiteOffers"
                value="no"
                checked={!websiteOffers.isOffer}
                onChange={(e) => {
                  dispatch(setPropertyWebsiteOfferToDefault());
                  dispatch(handleChange({ name: e.target.name, value: e.target.value }));
                }}
              />
            </div>
          </>
        }
        {(websiteOffers.isOffer || isPriveOffer) && (
          <div className="property-additional-data-main">
            <Input
              id='property-website-offer-title'
              dataAttribute='property-website-offer-title-input'
              label="* Special Offer Title"
              labelClassName="special-offer-title-label"
              placeHolder=""
              name="offerTitle"
              value={websiteOffers.offerTitle}
              onChange={(e) => {
                setOfferTitleHtmlPresent ( isHtmlPresent(e.target.value));
                dispatch(handleChange({ name: e.target.name, value: e.target.value }));
              }}
              className="special-offer-title"
            />
            {offerTitleHtmlPresent && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-25px',
                  marginBottom: '10px',
                }}
              >
                <div className='warning-width'><WarningIcon width={15} height={15} /></div>
                <p style={{ fontSize: '12px', color: 'red' }}>Error: HTML styling has been identified (highlighting, bolding, headings, etc.), please remove any styling as it will affect the way your content displays to guests.</p>
              </div>
            )}
            {showError && websiteOffers.offerTitle === '' && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-25px',
                  marginBottom: '10px',
                }}
              >
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Provide an offer title</p>
              </div>
            )}
            <span className="b-form-input__label special-offer-title-label">
              * Special Offer Main Description
            </span>
            <div className='special-offer-main-desc'>
              <QuillEditor initialContent={websiteOffers.offerDescription} onContentChange={(content: string) => {
                setOfferDescriptionHtmlPresent ( !validateRTEText(content));
                dispatch(handleChange({ name: "offerDescription", value: content }))
              }} />
            </div>
            {offerDescriptionHtmlPresent && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-10px',
                  marginBottom: '10px',
                }}
              >
                <div className='warning-width'><WarningIcon width={15} height={15} /></div>
                <p style={{ fontSize: '12px', color: 'red' }}>Error: HTML styling has been identified (highlighting, bolding, headings, etc.), please remove any styling as it will affect the way your content displays to guests.</p>
              </div>
            )}
            {showError && (tempDiv.textContent?.trim() === '' || tempDiv.textContent?.trim() === 'undefined') && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-7px',
                  marginBottom: '10px',
                }}
              >
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Provide a description</p>
              </div>
            )}
            <Input
              id='property-website-brand-manager'
              dataAttribute='property-website-brand-manager-input'
              label={`${offerType === 'hyatt-prive' ? '' : '*'}<a href="https://assets.hyatt.com/brandmanager.html" target="_blank">Hyatt Brand Manager</a> (HBM) Image URL`}
              labelClassName="special-offer-title-label"
              placeHolder=""
              name="brandManagerID"
              value={hbmUrl}
              onChange={hbmOnChangeHandler}
              className="special-offer-title"
            />
            {hbmUrlModified && (
              <div className="warning-component">
                <WarningIcon width={15} height={15} color={'orange'} />
                <p className="warning-message">Url have been modified</p>
              </div>
            )}
            {hbmImageInValid && (
              <div className="warning-component">
                <WarningIcon width={15} height={15} />
                <p className="error-message">The image you have selected is not valid. Please choose another image.</p>
              </div>
            )}
            {!hbmImageInValid && !hbmAspectRatiosAvailable && (
              <div className="warning-component">
                <WarningIcon width={15} height={15} />
                <p className="error-message">The selected image does not have both aspect ratios. Please choose another image.</p>
              </div>
            )}
            <img className='img-hbm' src={websiteOffers.brandManagerID} alt="hidden-hbm"
              onError={() => {
                if (websiteOffers.brandManagerID) {
                  setHbmImageInValid(true)
                  dispatch(handleChange({ name: 'validBrandManagerID', value: false }));
                }
                else {
                  setHbmImageInValid(false);
                  dispatch(handleChange({ name: 'validBrandManagerID', value: true }));

                }
              }}
              onLoad={() => {
                setHbmImageInValid(false);
                dispatch(handleChange({ name: 'validBrandManagerID', value: true }));
              }} />
            {showError && (offerType !== 'hyatt-prive' && websiteOffers.brandManagerID === '') && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-25px',
                  marginBottom: '10px',
                }}
              >
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Provide a brand manager ID</p>
              </div>
            )}
            {/* Code below commented out for: https://jira.hyattdev.com/browse/OM-1457 */}
            {/* <div className="property-datepicker-main">
              <p className="p-base-normal">
                Enter when the Offer materials need to be completed to meet the drop date to have
                the offer available.
              </p>
              <DatePickerComponent
                label="Materials Due Date"
                labelClassName="p-base-small materials-due-date-label"
                value={getMaterialsDueDate()}
                className="property-date"
                placeholder="Select Date"
                name="materialsDueDate"
                format="MMM-dd-yyyy"
                onChange={(date) =>
                  dispatch(handleChange({ name: 'materialsDueDate', value: setTimeTo9AMCST(date.toDateString()) }))
                }
                minDate={new Date()}
              />
            </div> */}
            {!isPriveOffer && <Checkbox
              id='property-website-translation-required'
              dataAttribute='property-website-translation-required-checkbox'
              labelClassName='translation-required'
              onChange={(e) => {
                dispatch(handleChange({ name: 'translationRequired', value: e.target.checked }));
              }}
              checked={websiteOffers.translationRequired}
              label={'Translation Required'}
            />}
            {showError && websiteOffers?.translationRequired && websiteOffers?.languages?.length === 0 && (
              <div className="inline-error">
                <WarningIcon width={15} height={15} />
                <p style={{ fontSize: '12px', color: 'red' }}>Please select atleast one language for translation</p>
              </div>
            )}
            {websiteOffers.translationRequired && <div className='translation-language-list'>
              <Checkbox
                id='property-website-translation-spanish'
                dataAttribute='property-website-translation-spanish-checkbox'
                label='Spanish (Universal)'
                checked={websiteOffers.languages.includes('es-ES')}
                onChange={(e) => {
                  languageSelection(e, 'es-ES');
                }}
              />
              <Checkbox
                id='property-website-translation-portuguese'
                dataAttribute='property-website-translation-portuguese-checkbox'
                label='Portuguese (Brazil)'
                checked={websiteOffers.languages.includes('pt-PT')}
                onChange={(e) => {
                  languageSelection(e, 'pt-PT');
                }}
              />
              <Checkbox
                id='property-website-translation-french'
                dataAttribute='property-website-translation-french-checkbox'
                label='French (France)'
                checked={websiteOffers.languages.includes('fr-FR')}
                onChange={(e) => {
                  languageSelection(e, 'fr-FR');
                }}
              />
              <Checkbox
                id='property-website-translation-german'
                dataAttribute='property-website-translation-german-checkbox'
                label='German'
                checked={websiteOffers.languages.includes('de-DE')}
                onChange={(e) => {
                  languageSelection(e, 'de-DE');
                }}
              />
              <Checkbox
                id='property-website-translation-russian'
                dataAttribute='property-website-translation-russian-checkbox'
                label='Russian'
                checked={websiteOffers.languages.includes('ru-RU')}
                onChange={(e) => {
                  languageSelection(e, 'ru-RU');
                }}
              />
              <Checkbox
                id='property-website-translation-korean'
                dataAttribute='property-website-translation-korean-checkbox'
                label='Korean'
                checked={websiteOffers.languages.includes('ko-KR')}
                onChange={(e) => {
                  languageSelection(e, 'ko-KR');
                }}
              />
              <Checkbox
                id='property-website-translation-japanese'
                dataAttribute='property-website-translation-japanese-checkbox'
                label='Japanese'
                checked={websiteOffers.languages.includes('ja-JP')}
                onChange={(e) => {
                  languageSelection(e, 'ja-JP');
                }}
              />
              <Checkbox
                id='property-website-translation-simplified-chinese'
                dataAttribute='property-website-translation-simplified-chinese-checkbox'
                label='Simplified Chinese'
                checked={websiteOffers.languages.includes('zh-CN')}
                onChange={(e) => {
                  languageSelection(e, 'zh-CN');
                }}
              />
              <Checkbox
                id='property-website-translation-traditional-chinese'
                dataAttribute='property-website-translation-traditional-chinese-checkbox'
                label='Traditional Chinese'
                checked={websiteOffers.languages.includes('zh-HK')}
                onChange={(e) => {
                  languageSelection(e, 'zh-HK');
                }}
              />
            </div>
            }
          </div>
        )}
        <div className="bottom-componenet"></div>
      </div>
    </section>
  );
};
