import { PageHeader } from '../../components/molecules/PageHeader';
import { OfferDetails } from '../../components/molecules/OfferDetails/offerDetails';
import './reviewAndSubmit.scss';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { CommentsParams, handleChange } from '../../store/offerToolSlice';
import { extractOfferCategory, extractPath } from '../../utils/fileUtils';
import { useLocation } from 'react-router-dom';
import { getComments } from '../../service/api';
import { store } from '../../store/store';
import { HeaderDetails } from '../../components/molecules/HeaderDetails/headerDetails';
import ViewComponent from '../viewOffer/ViewComponent';
import Alert from '../../components/atoms/Alert';

export const ReviewAndSubmit: React.FC = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { offerComments, offers, isCopyOffer, fromReviewAndSubmit } = useSelector((state: any) => state.offerTool);
  const { pathname, ...location } = useLocation();
  const offerType = extractOfferCategory(location.search);
  const offerPath = extractPath(location.search);
  const [userComments, setUserComments] = useState<CommentsParams[]>();
  const [rejectReason, setRejectReason] = useState('');
  const [showCopiedAlert, setShowCopiedAlert] = useState(false);
  const [isLaunchOffer, setIsLaunchOffer] = useState(false);

  useEffect(()=>{    
    const isLaunchOffer = offerPath.includes('/content/launches/') && offers?.status?.toLocaleLowerCase() === 'draft';
    setIsLaunchOffer(isLaunchOffer);    
  },[offers?.status, offerPath]);

  useEffect(() => {
    if (offerType === 'toolbox-offer') {
      dispatch(handleChange({ name: 'page', value: 9 }));
    } else {
      dispatch(handleChange({ name: 'page', value: 13 }));
    }
    if(isCopyOffer){
      setShowCopiedAlert(true);
    }
  }, []);

  useEffect(() => {
    getComment();
    window.utag_data.page_name = "offertool-reviewandsubmit";
  }, []);

  const getComment = useCallback(() => {
    try {
      dispatch(getComments(offerPath)).then((e) => {
        if (e.meta.requestStatus === 'fulfilled') {
          const offerComments = store.getState().offerTool.offerComments;
          let commentsArr: CommentsParams[] = [];
          for (const key in offerComments) {
            if (
              offerComments?.hasOwnProperty(key) &&
              typeof offerComments[key] === 'object' &&
              'jcr:description' in offerComments[key]
            ) {
              const dynamicObject = offerComments[key];
              const jcrDescription = dynamicObject['jcr:description'];
              const jcrCreatedBy = dynamicObject['jcr:createdBy'];
              const jcrCreated = dynamicObject['jcr:created'];
              if (jcrDescription?.split('/')[0] === 'reject') {
                setRejectReason((jcrDescription?.split('/').slice(1).join('/'))?.replace(/\$#@!/g, "%")
                  .replace(/\^\$@\*/g, "&")
                  .replace(/@!\$~/g, "+"),);
              }
              if (
                jcrDescription?.split('/')[0] === 'reject' ||
                jcrDescription?.split('/')[0] === 'accept' ||
                jcrDescription?.split('/')[0] === 'stepback' ||
                jcrDescription?.split('/')[0] === 'Comment'
              ) {
                commentsArr.push({
                  author: jcrCreatedBy,
                  comments: jcrDescription?.split('/').slice(1).join('/')
                    ?.replace(/\$#@!/g, "%")
                    .replace(/\^\$@\*/g, "&")
                    .replace(/@!\$~/g, "+"),

                  date: jcrCreated,
                  status:
                    jcrDescription?.split('/')[0] === 'stepback' ||
                      jcrDescription?.split('/')[0] === 'Comment'
                      ? jcrDescription?.split('/')[0]
                      : jcrDescription?.split('/')[0] + 'ed',
                });
              } else {
                commentsArr.push({
                  author: jcrCreatedBy,
                  comments: jcrDescription?.split('/').slice(1).join('/')
                    ?.replace(/\$#@!/g, "%")
                    .replace(/\^\$@\*/g, "&")
                    .replace(/@!\$~/g, "+"),
                  date: jcrCreated,
                  status: '',
                });
              }
            }
          }
          setUserComments(commentsArr);
          commentsArr = [];
        }
      });
    } catch (error) {}
  }, [offerComments]);

  const getHeadingText = () => {
    if(isCopyOffer){
      return 'This is a copy of an existing offer. Please enter the information that needs to be changed for this new offer.'
    }else{
      return 'Review all the information about your offer and edit if necessary.'
    }
  }

  return (
    <section className="container">
      {showCopiedAlert && !fromReviewAndSubmit && (
        <Alert
          message="You can edit additional info for this offer from this screen before submitting the offer."
          title="Copy of Offer Created"
          type="success"
          onClose={() => {
            setShowCopiedAlert(false);
          }}
        />
      )}
      {pathname === '/review-and-submit' && offers?.status === 'Rejected' ? (
        <>
          <PageHeader title={offers?.promotionName} description="" />
          <HeaderDetails />
          <ViewComponent status={offers.status} reason={rejectReason} />
        </>
      ) : (
        <PageHeader
          title="Review & Submit"
          description={`${getHeadingText()} <br/> You will <span style=font-weight:bold>NOT</span> be able to edit this offer once it's submitted.`}
        />
      )}
      <hr className="hr" />
      <OfferDetails userComments={userComments} getComment={getComment} isLaunchOffer={isLaunchOffer} />
    </section>
  );
};