import Modal from '../../components/atoms/Modal';
import { Button } from '../../components/atoms/Button';
import './PricingDetails.css';

interface Props {
    handleClose: () => void;
}

function NonQualifiedBookingDatesValidationModal({ handleClose }: Props) {
    return (
        <Modal cautionIconVisible={false} titleSize="25px" title="Change to Booking Dates" onClose={handleClose} size='medium'>
            <>
                <div className='pricing-modal-body'>By Changing Offer Rate Details for this offer, booking end date will get cleared. Please review the booking dates before submitting your offer.
                </div>
                <div className="next-back-btn-container button-container">
                    <Button id='pricing-detials-modal-primary-button' dataAttribute='pricing-detials-modal-button' label={'OK'} primary onClick={handleClose} />
                </div>
            </>
        </Modal>
    );
}

export default NonQualifiedBookingDatesValidationModal;
