import React, { useEffect, useMemo } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';

import { DatePickerComponent } from '../../components/molecules/datePicker';
import { hyattPriveBookingLabel, startEndData } from './startEndData';
import './BookingStartAndEnd.css';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/atoms/Banner';
import { Radio } from '../../components/atoms/Radio';
import { handleChange, type OfferFeatureFlag } from '../../store/offerToolSlice';
import { WarningIcon } from '../../components/Icons/WarningIcon';
import { format } from 'date-fns';
import { extractOfferCategory } from '../../utils/fileUtils';
import { useLocation } from 'react-router-dom';
import { getDaysBetweenDates, setTimeTo9AMCST } from '../../utils/dateAndTimeUtils';
import { isDSAssociate, isDSMarketing } from '../../utils/userUtils';
import { parseBoolean } from '../../utils/textUtils';

export const BookingStartAndEnd: React.FC = () => {
  const {
    offers: {
      pricing: { offerRack, qualifiers },
      bookingDates: { bookingStartDate, bookingEndDate, stayStartDate, stayEndDate, updateDates },
      offerCategory,
      offerType,
    },
    showError,
    offersFeatureFlags
  } = useSelector((state: any) => state.offerTool);

  const dateOverrideFlag = offersFeatureFlags.find((el: OfferFeatureFlag) => el?.text === 'date-override');
  const isDateOverrideEnabled = dateOverrideFlag ? parseBoolean(dateOverrideFlag.value) : false;

  const dispatch = useDispatch();
  const location = useLocation();
  const isToolBoxOffer = extractOfferCategory(location.search) === 'toolbox-offer';

  useEffect(() => {
    const from = location.state?.from;
    if (from === 'edit') {
      dispatch(handleChange({ name: 'isUpdate', value: true }));
    }
  }, []);

  useEffect(() => {
    if (isToolBoxOffer) {
      dispatch(handleChange({ name: 'page', value: 5 }));
    } else {
      dispatch(handleChange({ name: 'page', value: 6 }));
    }
  }, []);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-bookingstartandend';
  }, []);

  const getStartDates = (cal1Value: string) => {
    try {
      if (cal1Value === 'bookingStartDate') {
        if (bookingStartDate !== '') {
          try {
            const bookingStartDateIO = new Date(bookingStartDate);
            const formattedDate = format(bookingStartDateIO, 'MMM-dd-yyyy');
            return new Date(formattedDate);
          } catch (error) {}
        } else {
          return undefined;
        }
      } else {
        if (stayStartDate !== '') {
          try {
            const stayStartDateIO = new Date(stayStartDate);
            const formattedDate = format(stayStartDateIO, 'MMM-dd-yyyy');
            return new Date(formattedDate);
          } catch (error) {}
        } else {
          return undefined;
        }
      }
    } catch (error) {}
  };

  const getEndDates = (cal2Value: string) => {
    try {
      if (cal2Value === 'bookingEndDate') {
        if (bookingEndDate !== '') {
          try {
            const bookingEndDateIO = new Date(bookingEndDate);

            const formattedDate = format(bookingEndDateIO, 'MMM-dd-yyyy');
            return new Date(formattedDate);
          } catch (error) {}
        } else {
          return undefined;
        }
      } else {
        if (stayEndDate !== '') {
          try {
            const stayEndDateIO = new Date(stayEndDate);
            const formattedDate = format(stayEndDateIO, 'MMM-dd-yyyy');
            return new Date(formattedDate);
          } catch (error) {}
        } else {
          return undefined;
        }
      }
    } catch (error) {}
  };

  let startDategreateThenEnd = true;
  let startNotWithinLimit = true;
  let stayStartAndEndDatesAreSame = true;
  let stayStartbeforeBookingStart = false;
  let stayEndDateAfterBookingEnd = true;
  const todayDate = new Date();

  if (stayStartDate || stayEndDate) {
    const stayStartDateObj = new Date(stayStartDate);
    const stayEndDateObj = new Date(stayEndDate);
    startDategreateThenEnd = getDaysBetweenDates(stayStartDateObj, stayEndDateObj) < 0;
    stayStartAndEndDatesAreSame = getDaysBetweenDates(stayStartDateObj, stayEndDateObj) === 0;
    if (offerCategory === 'toolbox-offer') {
      startNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, stayStartDateObj) < 5;
    } else {
      startNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, stayStartDateObj) < 15;
    }
  }

  let bookingStartDategreateThenEnd = true;
  let bookingStartNotWithinLimit = true;
  let bookingDateWindowValidationFail = false;

  if (bookingStartDate || bookingEndDate) {
    const bookingStartDateObj = new Date(bookingStartDate);
    const bookingEndDateObj = new Date(bookingEndDate);
    bookingStartDategreateThenEnd = getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) < 1;
    if (offerCategory === 'toolbox-offer') {
      bookingStartNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, bookingStartDateObj) < 5;
      bookingDateWindowValidationFail =
        getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) > 90;
    } else {
      bookingStartNotWithinLimit = isDSAssociate() || isDSMarketing() ? false : getDaysBetweenDates(todayDate, bookingStartDateObj) < 15;
      bookingDateWindowValidationFail =
        offerRack === 'less-than-rack' &&
        qualifiers === 'nonQualifiedCustomers' &&
        getDaysBetweenDates(bookingStartDateObj, bookingEndDateObj) > 90;
    }
  }
  if (bookingStartDate && stayStartDate) {
    stayStartbeforeBookingStart =
      getDaysBetweenDates(new Date(stayStartDate), new Date(bookingStartDate)) > 0;
  }
    stayEndDateAfterBookingEnd = getDaysBetweenDates(new Date(stayEndDate), new Date(bookingEndDate)) > 0;

  const hasMaxDate = (field: string) => {
    if (offerRack === 'less-than-rack' && qualifiers !== 'qualifiedCustomers') {
      if (field === 'bookingStartDate') {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 90);
        return currentDate;
      }
      if (field === 'bookingEndDate' && bookingStartDate) {
        let startDate = new Date(bookingStartDate)
        startDate.setDate(startDate?.getDate() + 90);
        return startDate;
      }
    }
    return undefined;
  };

  const hasMinDate = (field: string) => {
    if(isDSAssociate() || isDSMarketing())
      return undefined;
    var currentDate = new Date();
    if (field === 'bookingEndDate') {
      isToolBoxOffer ? currentDate.setDate(currentDate.getDate() + 5) : currentDate.setDate(currentDate.getDate() + 15);
    }
    return currentDate;
  };

  const subHeader = useMemo(() => {
    let description = `Booking dates cannot be within <span class='noto-700'>${offerCategory === 'toolbox-offer' ? '5 business days' : '15 business days'}</span> of the offer being submitted.
    Booking <span class='noto-700'>start dates</span> cannot be in the past and booking <span class='noto-700'>end dates</span> cannot be before the start date.
  `;
    if (offerCategory === 'toolbox-offer') {
      if (offerType === 'htb-percent-off') {
        description += "<span class='noto-700'>Booking and stay dates are for content only</span>, <span>NOT for the addition or removal of guarantee/cancel policies.</span>";
      }
      else if (offerType === 'htb-rack-plus-attribute') {
        description += "<span class='noto-700'>Booking and stay dates are for content only</span>, <span>NOT for the addition or removal of inclusions or guarantee/cancel policies.</span>";
      }
      else if (offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox' || offerType === 'woh-bonus-points-toolbox-offer') {
        description += "<span class='noto-700'>Booking and stay dates are for content only</span>, <span>NOT for changes to the guarantee/deposit policy.</span>";
      }
    }

    return description;
  }, [offerCategory, offerType])

  const handleGoLiveChange = (value: 'override' | 'nooverride') => dispatch(handleChange({
    name: 'updateDates', value }
  ));

  return (
    <section className="container start-end-container">
      <PageHeader
        title="Booking start & end dates"
        description={subHeader}
      />
      {showError && (
        <div style={{ width: '75%', marginBottom: '20px' }}>
          <Banner title="Required Fields" message="Please correct the errors below." />
        </div>
      )}

      <article className="start-end-dates">
        {startEndData.map((
          { label, subLabel, subLabelToolbox, cal1, cal2, cal1Value, cal2Value }, index
        ) => {
          const isLast = index === startEndData.length - 1;

          return (
            <div key={index} className={'date-section ' + (isLast ? 'mb-0' : '') }>
              <p className="p-base-normal enter-date-info">{
                (index === 0 && offerType === 'hyatt-prive')
                  ? hyattPriveBookingLabel 
                  : label}
              </p>
              <p className="p-base-small">
                {offerCategory === 'toolbox-offer' ? subLabelToolbox : subLabel}
              </p>
              <div className={'start-end ' + (isLast ? 'mb-0': '')}>
                <div>
                  <DatePickerComponent
                    label={cal1}
                    placeholder="Select Date"
                    className="booking-date-picker"
                    labelClassName="p-base-small"
                    format="MMM-dd-yyyy"
                    value={getStartDates(cal1Value)}
                    onChange={(date) => {
                      const formattedDate = setTimeTo9AMCST(date.toDateString());
                      dispatch(handleChange({ name: cal1Value, value: formattedDate }));
                      if (cal1Value === 'bookingStartDate') {
                        dispatch(handleChange({ name: 'bookingEndDate', value: '' }));
                      }
                    }}
                    maxDate={hasMaxDate(cal1Value)}
                    minDate={hasMinDate(cal2Value)}
                  />
                  {index === 0 && showError && bookingStartDate && (
                    <>
                      {bookingDateWindowValidationFail ? (
                        <div className="error-component">
                          <WarningIcon width={15} height={15} />
                          <p className="error-label">
                            Booking dates must be within a 90-day window.
                          </p>
                        </div>
                      ) : (
                        bookingStartNotWithinLimit && (
                          <div className="error-component">
                            <WarningIcon width={15} height={15} />
                            <p className="error-label">Please choose a valid booking start date</p>
                          </div>
                        )
                      )}
                    </>
                  )}
                  {index === 0 && showError && !bookingStartDate && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Please choose booking start date</p>
                    </div>
                  )}
                  {index === 1 && showError && !stayStartDate && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Please choose stay start date</p>
                    </div>
                  )}
                  {index === 1 &&
                    showError &&
                    stayStartDate &&
                    (startNotWithinLimit || stayStartbeforeBookingStart) && (
                      <div className="error-component">
                        <WarningIcon width={15} height={15} />
                        <p className="error-label">Please choose valid stay start date</p>
                      </div>
                    )}
                </div>
                <div>
                  <DatePickerComponent
                    label={cal2}
                    placeholder="Select Date"
                    className="booking-date-picker"
                    labelClassName="p-base-small"
                    format="MMM-dd-yyyy"
                    value={getEndDates(cal2Value)}
                    onChange={(date) => {
                      const formattedDate = setTimeTo9AMCST(date.toDateString());
                      dispatch(handleChange({ name: cal2Value, value: formattedDate }));
                    }}
                    maxDate={hasMaxDate(cal2Value)}
                    minDate={hasMinDate(cal2Value)}
                  />
                  {index === 0 && showError && !bookingEndDate && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Please choose booking end date</p>
                    </div>
                  )}
                  {index === 0 &&
                    showError &&
                    bookingStartDate &&
                    bookingEndDate &&
                    bookingStartDategreateThenEnd && (
                      <div className="error-component">
                        <WarningIcon width={15} height={15} />
                        <p className="error-label">Please choose valid booking end date</p>
                      </div>
                    )}
                  {index === 1 && showError && !stayEndDate && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Please choose stay end date</p>
                    </div>
                  )}
                  {index === 1 && showError && stayEndDate && startDategreateThenEnd && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Please choose valid stay end date</p>
                    </div>
                  )}
                  {index === 1 && showError && stayEndDate && stayStartAndEndDatesAreSame && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Stay start date and end date can not be same</p>
                    </div>
                  )}
                  {index === 1 && showError && stayEndDate && bookingEndDate && stayEndDateAfterBookingEnd && (
                    <div className="error-component">
                      <WarningIcon width={15} height={15} />
                      <p className="error-label">Stay end date should not be earlier than booking end date</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        {isDateOverrideEnabled && (
          <div>
            <p className="p-base-small date-section">For direct offers only: The offer might be ready to publish to a property website sooner than 15 days.</p>
            {showError && updateDates === null && (
              <div className="error-component">
                <WarningIcon width={15} height={15} />
                <p className="error-label">Please choose an option below</p>
              </div>
            )}
            <Radio
              checked={updateDates === 'override'}
              dataAttribute="update-dates-override"
              id="update-dates-override"
              label="*I want my offer to go live as soon as it's approved."
              labelClassName="p-base-normal"
              name="update-dates-override"
              value="override"
              onChange={() => handleGoLiveChange('override')}
            />
            <Radio
              checked={updateDates === 'nooverride'}
              dataAttribute="update-dates-no-override"
              id="update-dates-no-override"
              label="*I want my offer to go live on the specified booking start date."
              labelClassName="p-base-normal"
              name="update-dates-no-override"
              value="nooverride"
              onChange={() => handleGoLiveChange('nooverride')}
            />
          </div>
        )}
      </article>
    </section>
  );
};
